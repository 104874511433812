import { configureStore } from '@reduxjs/toolkit';
import userReducer from './users/usersSlice';
import referralsReducer from "./referrals/referralsSlice";
import rewardsReducer from "./rewards/rewardsSlice";
import redeemsReducer from "./redeems/redeemsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    referrals: referralsReducer,
    rewards: rewardsReducer,
    redeems: rewardsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
