import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../services/AxiosInstance";
import { getAPIEndPoints } from "../../utils/api";
import { AppDispatch, RootState } from "../store";
import { initialState } from "../../types/users/userState";
import { getTotalRewards } from "../rewards/rewardsSlice";

// 🔥 Fetch NoCodeDB User
export const getInitialData = createAsyncThunk<any, void, { dispatch: AppDispatch }>(
  "user/getInitialData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Check if the user data exists in localStorage
      const phoneNumber = localStorage.getItem("phoneNumber");
      
      if (!phoneNumber) {
        // If there's no phone number, reject with an error message
        return rejectWithValue("User phone number not found");
      }

      // Fetch user details from NoCodeDB or your database
      const response = await dispatch(getUserDetails(phoneNumber));
      // Return the user details
      return response.payload;
      
    } catch (error) {
      // Catch any errors and reject the promise with an error message
      return rejectWithValue("Failed to fetch initial user data");
    }
  }
);


// 🚀 Fetch User Details from NoCodeDB (Now always returns `list[0]`)
export const getUserDetails = createAsyncThunk<any, string>(
  "user/getUserDetails",
  async (mobile_number, { rejectWithValue }) => {
    const query = `(mobile_number,eq,${mobile_number})`;
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.users(), {
        params: { where: query },
      });

      // ✅ Directly return `list[0]`, so no need to extract `list` later!
      return response.data.list.length > 0 ? response.data.list[0] : null;
    } catch (error) {
      return rejectWithValue("Error fetching user details");
    }
  }
);

// ➕ Create User in NoCodeDB (Signup)
export const createUser = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "user/createUser",
  async (data, { dispatch, rejectWithValue }) => {
    try {
        let query = `(mobile_number,eq,${data?.mobile_number})`;
        if(data?.email){
            query = query + `~or(email,eq,${data?.email})`;
        }
        query = query + `~and(is_verified,eq,true)`;
        const response = await AxiosInstance.get(getAPIEndPoints.users(), {
            params: { where: query }
        });
        const isAlreadyExist = Boolean(response.data.list.length);
        console.log('isAlreadyExist', isAlreadyExist);
        if(isAlreadyExist){
            return rejectWithValue("User has been already created with mobile number or email.");
        }
        // 🔥 Create user in NoCodeDB
        const user = await AxiosInstance.post(getAPIEndPoints.users(), {
            data
        });

        const userId = user.data?.Id;
        if (userId) {
            // ✅ Immediately fetch user details using `mobile_number`
            return await dispatch(getUserDetails(data?.mobile_number)).unwrap();
        } else {
            return rejectWithValue("Failed to retrieve user details after signup.");
        }
    } catch (error) {
      return rejectWithValue("Failed to create user.");
    }
  }
);

// 🔄 Update User Details
export const updateUserDetails = createAsyncThunk<any, any>(
    "user/updateUserDetails",
    async (data, { rejectWithValue }) => {
      try {
        if (data?.email) {
          const { data: responseData } = await AxiosInstance.get(getAPIEndPoints.users(), {
            params: { where: `(email,eq,${data.email})` },
          });
  
          const existingUser = responseData.list?.[0];
  
          if (existingUser && existingUser.Id !== data.Id) {
            return rejectWithValue("User has already been created with this email.");
          }
        }
  
        await AxiosInstance.patch(getAPIEndPoints.users(), { data });
        return data;
      } catch (error) {
        console.error("Error updating user details:", error);
        return rejectWithValue("Failed to update profile. Please try again.");
      }
    }
  );
  

const { actions, reducer } = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 🔥 Handle `getInitialData`
      .addCase(getInitialData.pending, (state) => {
        state.isFetching = true;  // Indicate fetching started
        state.loading = true;
      })
      .addCase(getInitialData.fulfilled, (state, action) => {
        state.loading = false;
        state.isFetching = false;
        state.user = action.payload || null;
      })
      .addCase(getInitialData.rejected, (state, action) => {
        state.isFetching = false;
        state.loading = false;
        state.error = action.payload as string;
      })

      // 🚀 Handle `getUserDetails` (No need to extract `list[0]` anymore)
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // ➕ Handle `createUser`
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // 🔄 Handle `updateUserDetails`
      .addCase(updateUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.user = { ...state.user, ...action.payload }; // Merge updated values into state
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {} = actions;
export default reducer;