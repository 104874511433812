import axios from 'axios';
import { GUPSHUP_API_BASE_URL, GUPSHUP_SOURCE_NAME, GUPSHUP_SOURCE_NUMBER, GUPSHUP_API_ENDPOINT, GUPSHUP_API_KEY, GUPSHUP_OTP_TEMPLATE_ID } from '../utils/constants';

const gupshup = {
  sendGupshupWA: async (
    destination: string,
    params: any[]
  ) => {
    const body = new URLSearchParams({
      channel: 'whatsapp', // Specify the channel as WhatsApp
      source: GUPSHUP_SOURCE_NUMBER, // Your WhatsApp bot or sender's number
      destination,
      'src.name': GUPSHUP_SOURCE_NAME, // Ensure the src.name is correct
    });

    // Construct the template object with id and params
    const template = {
      id: GUPSHUP_OTP_TEMPLATE_ID, // Template ID for OTP or invitation message
      params: params.map((param) => encodeURIComponent(param)), // Encoding params
    };

    // Add the template object to the URLSearchParams
    body.append('template', JSON.stringify(template));

    const gupshupHeaders = {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded', // Required for Gupshup
      'apikey': GUPSHUP_API_KEY as string, // Ensure the API key is correct
    };

    const options = {
      method: 'POST',
      headers: gupshupHeaders,
      data: body.toString(), // Ensure data is stringified properly for URLSearchParams
    };

    const url = `${GUPSHUP_API_BASE_URL}${GUPSHUP_API_ENDPOINT}`;

    try {
      const response = await axios(url, options);
      console.log('Response from Gupshup:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Error sending message via Gupshup:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
};

export default gupshup;
