export const getAPIEndPoints = Object.freeze({
  users: () => "/api/v2/tables/mr1myokv6zashxf/records",
  referrals: () => "/api/v2/tables/mysottdl5mdd6ls/records",
  rewards: () => "/api/v2/tables/mnflnqti41i65xh/records",
  redeems: () => "/api/v2/tables/mtqduxefrnsalkp/records",
  logs: () => "/api/v2/tables/mkin7zpgayq1faf/records",
  slackNotifications: {
    createReferral: () => "/referral/create",
    createUser: () => "/user/create",
    requestForDistributorRole: () => "/user/request-for-distributor-role",
    createRedeem: () => "/redeem/create",
  },
  yaakeNotifications: {
    createReferrals: () => "/api/lead_contact_generation/",
  },
});
