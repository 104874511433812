import React, { useState } from "react";
import { Form, Input, Button, Radio, Checkbox, message, Tooltip } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { createReferral } from "../redux/referrals/referralsSlice";

interface ReferralFormValues {
  organisationType: "diagnostic_center" | "hospital";
  organisationName: string;
  refereeName: string;
  contactNumber: string;
  email?: string;
  shareInfo?: boolean;
}

const Referral: React.FC = () => {
  const [form] = Form.useForm<ReferralFormValues>();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ReferralFormValues): Promise<void> => {
    setLoading(true);
    try {
      const referralData = {
        user_fk: user?.Id || "",
        organisation_type: values.organisationType,
        organisation_name: values.organisationName,
        referred_name: values.refereeName,
        referred_mobile_number: values.contactNumber,
        referred_email: values.email || "",
        share_personal_info: values.shareInfo ? "true" : "false",
      };

      await dispatch(createReferral(referralData)).unwrap();
      message.success("Referral submitted successfully!");
      form.resetFields();
    } catch (error: any) {
      message.error(error || "Failed to submit referral. Please try again.");
    }
    setLoading(false);
  };

  return (  
  <StyledContainer>
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {/* Organisation Type */}
      <Form.Item
        name="organisationType"
        label={
          <FormItemLabel>
            <Tooltip title="Select whether the referred organization is a Diagnostic Center or a Hospital.">
              Organisation Type
            </Tooltip>
          </FormItemLabel>
        }
        rules={[{ required: true, message: "Please select an organisation type" }]}
      >
        <Radio.Group>
          <Radio value="diagnostic_center">Diagnostic Center</Radio>
          <Radio value="hospital">Hospital</Radio>
        </Radio.Group>
      </Form.Item>
  
      {/* Organisation Name */}
      <Form.Item
        name="organisationName"
        label={
          <FormItemLabel>
            <Tooltip title="Enter the name of the hospital or diagnostic center you are referring to.">
              Organisation Name
            </Tooltip>
          </FormItemLabel>
        }
        rules={[{ required: true, message: "Please enter organisation name" }]}
      >
        <StyledInput placeholder="Enter organisation name" />
      </Form.Item>
  
      {/* Referee Name */}
      <Form.Item
        name="refereeName"
        label={
          <FormItemLabel>
            <Tooltip title="Provide the name of the person we can contact at the referred organisation.">
              Referee Name
            </Tooltip>
          </FormItemLabel>
        }
        rules={[{ required: true, message: "Please enter referee name" }]}
      >
        <StyledInput placeholder="Enter referee name" />
      </Form.Item>
  
      {/* Contact Number */}
      <Form.Item
        name="contactNumber"
        label={
          <FormItemLabel>
            <Tooltip title="Enter the referee’s contact number for communication purposes.">
              Contact Number
            </Tooltip>
          </FormItemLabel>
        }
        rules={[
          { required: true, message: "Please enter contact number" },
          { pattern: /^[0-9]{10}$/, message: "Enter a valid 10-digit number" },
        ]}
      >
        <StyledInput placeholder="Enter contact number" maxLength={10} />
      </Form.Item>
  
      {/* Email */}
      <Form.Item
        name="email"
        label={
          <FormItemLabel>
            <Tooltip title="Provide the referee’s email address if available.">
              Email
            </Tooltip>
          </FormItemLabel>
        }
        rules={[{ type: "email", message: "Enter a valid email" }]}
      >
        <StyledInput placeholder="Enter email (optional)" />
      </Form.Item>
  
      {/* Share Information Checkbox */}
      <Form.Item
        name="shareInfo"
        valuePropName="checked"
        label={
          <FormItemLabel>
            <Tooltip title="Check this box if you agree to share your details with the referee while we connect with them.">
              Share Personal Info
            </Tooltip>
          </FormItemLabel>
        }
      >
        <Checkbox>Would you be okay sharing your info while we talk to the Referee?</Checkbox>
      </Form.Item>
  
      {/* Submit Button */}
      <StyledButton type="primary" htmlType="submit" loading={loading}>
        Submit
      </StyledButton>
    </Form>
  </StyledContainer>  
  );
};

export default Referral;

// 🔹 Styled Components
const StyledContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 45px;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 35px;
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 25px;
    border-radius: 20px;
  }
`;

const FormItemLabel = styled.label`
    font-size: 14px;
    font-weight: 600;
    color: #444;
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-tooltip {
    font-size: 10px;
    }    
`;

const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
  font-size: 16px;

  @media (max-width: 768px) {
    height: 38px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 36px;
    font-size: 14px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 373px;
  height: 50px;
  background: #0a2fb6;
  border-radius: 60px;
  font-size: 16px;
  margin-top: 20px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #082794;
  }

  @media (max-width: 768px) {
    height: 45px;
    font-size: 15px;
    border-radius: 50px;
  }

  @media (max-width: 480px) {
    height: 40px;
    font-size: 14px;
    border-radius: 40px;
  }
`;

