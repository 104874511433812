import React, { useEffect, useState } from "react";
import { Table, Row, Col, Steps, Spin, Alert } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { getReferralsList } from "../redux/referrals/referralsSlice";
import Coin from "../assets/coins.svg";
import { UserRole } from "../types/users/userEnums";

const Tracker: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { referrals, total, loading, error } = useSelector((state: RootState) => state.referrals);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();



  useEffect(() => {
    dispatch(getReferralsList({ page: currentPage }));
}, [dispatch, currentPage]);

const handlePageChange = (page: number) => {
    setCurrentPage(page);
};

    // Function to handle row expansion
    const handleRowExpand = (record: any) => {
        setExpandedRow(expandedRow === record.Id ? null : record.Id); // Toggle expanded row
    };

  const expandedRowRender = (record: any): React.ReactNode => {    
    const stepList = [
      { title: "500", description: "5 Cases", coins: 500, status: record.total >= 5 ? "finish" : "wait" },
      { title: "2500", description: `100 CT/MRI or 500 XRAY`, coins: 2500, status: record.CT_MRI >= 100 || record.XRAY >= 500 ? "finish" : "wait" },
      { title: "10000", description: `500 CT/MRI or 2500 XRAY`, coins: 10000, status: record.CT_MRI >= 500 || record.XRAY >= 2500 ? "finish" : "wait" },
      { title: "25000", description: `1000 CT/MRI or 5000 XRAY`, coins: 25000, status: record.CT_MRI >= 1000 || record.XRAY >= 5000 ? "finish" : "wait" },
    ];
    return (
      <ExpandedRowWrapper>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6}>
            <CaseCountWrapper>
              <strong>Case Count within 30 days</strong>
              <div>
                <CaseCount>{record.total || 0}</CaseCount>
                <CaseDetails>CT/MRI: {record.CT_MRI || 0} | XRAY: {record.XRAY || 0}</CaseDetails>
              </div>
            </CaseCountWrapper>
          </Col>

          <Col xs={24} md={18}>
            <StepsContainer>
              <Steps
                current={stepList.map((step) => step.status).lastIndexOf("finish")}
                direction="horizontal"
                size="small"
                items={stepList.map((step) => ({
                  title: (
                    <StepItem>
                      <img src={Coin} alt="Coin" />
                      <div>{step.coins}</div>
                    </StepItem>
                  ),
                  description: (<StepDescription>{step.description}</StepDescription>),
                }))}
                progressDot
              />
            </StepsContainer>
          </Col>
        </Row>
      </ExpandedRowWrapper>
    );
  };

  const columns = [
        { title: "Organization Name", dataIndex: "organisation_name", key: "organisation_name" },
        { title: "Referee Name", dataIndex: "referred_name", key: "referred_name" },
        { title: "Mobile", dataIndex: "referred_mobile_number", key: "referred_mobile_number" },
        {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (status: string) => <StatusText $status={status}>{status?.charAt(0).toUpperCase() + status?.slice(1)}</StatusText>
    },
        // Conditionally include the "Reward" column based on user role
        ...( [UserRole.CLIENT_ID_REFERRER, UserRole.DISTRIBUTOR].includes(user?.role) ? [
            {
                title: "Reward",
                dataIndex: "reward",
                key: "reward",
                render: (_: any, record: any) => (
                <RewardContainer>
                    <img src={Coin} alt="Coin" />
                    <span>{record.reward || 0}</span>
                    {/* {[UserRole.CLIENT_ID_REFERRER, UserRole.DISTRIBUTOR].includes(user?.role) && ( */}
                        <ExpandButton onClick={() => handleRowExpand(record)}>
                            {expandedRow === record.Id ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                        </ExpandButton>
                    {/* )} */}
                </RewardContainer>
                )
            },
        ] : [] ),
    ];

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message={error} type="error" />;

  return (
    <TrackerContainer>
        <StyledTable
            columns={columns}
            dataSource={referrals}
            pagination={{
                current: currentPage,
                pageSize: 10,
                total: total,
                onChange: handlePageChange,
            }}
            expandable={{
                expandedRowRender,
                expandedRowKeys: expandedRow ? [expandedRow] : [],
                onExpand: (expanded: boolean, record: any) => handleRowExpand(record), // Toggle expansion on row click
                expandIconColumnIndex: -1, // Control which column contains the expand icon (if needed)
            }}
            rowKey="Id"
        />
    </TrackerContainer>
  );
};

export default Tracker;

// 🔹 Styled Components
const TrackerContainer = styled.div`
width: 100%;
overflow-x: auto; /* Enables horizontal scrolling */
-webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */

@media (max-width: 768px) {
  padding-bottom: 10px;
}
`;

const StyledTable = styled(Table)`
width: 100%;
min-width: 600px; /* Ensures table doesn't shrink too much */
border: 1px solid #ccc;
border-radius: 10px;

.ant-table-thead > tr > th {
  text-align: center !important;
  font-size: 16px;
  white-space: nowrap; /* Prevents text from wrapping */

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.ant-table-tbody > tr > td {
  text-align: center !important;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from wrapping */

  @media (max-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}
`;

const ExpandedRowWrapper = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ebf4ff;

  @media (max-width: 768px) {
    padding: 15px;
    border-radius: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    border-radius: 10px;
  }
`;

const CaseCountWrapper = styled.div`
  strong {
    font-size: 18px;
    color: #040815;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const CaseCount = styled.span`
  color: #0a2fb6;
  font-size: 25px;
  padding: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    padding: 6px;
  }
`;

const CaseDetails = styled.small`
  font-size: 12px;
  color: #040815;

  @media (max-width: 768px) {
    font-size: 11px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const StepsContainer = styled.div`
  width: 100%;
`;

const StepItem = styled.div`
  text-align: center;

  img {
    width: 15px;

    @media (max-width: 768px) {
      width: 12px;
    }

    @media (max-width: 480px) {
      width: 10px;
    }
  }

  div {
    font-size: 12px;
    margin-top: 5px;

    @media (max-width: 768px) {
      font-size: 11px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }
`;

const StepDescription = styled.div`
  word-wrap: break-word; 
  white-space: normal; 
`;

export const RewardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;

  img {
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 18px;
      height: 18px;
    }

    @media (max-width: 480px) {
      width: 16px;
      height: 16px;
    }
  }

  span {
    font-weight: bold;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

const ExpandButton = styled.span`
  cursor: pointer;
  font-size: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-left: 15px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-left: 10px;
  }
`;

const StatusText = styled.span<{ $status: string }>`
  color: ${({ $status }) =>
    $status === "completed"
      ? "green"
      : $status === "onboarded"
      ? "blue"
      : $status === "referred"
      ? "orange"
      : "gray"};

  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
