// actionTypes.ts

export enum ActionTypes {
    LOGIN = "login",
    LOGOUT = "logout",
    OTP_SEND = "otp_send",
    OTP_FAILED = "otp_failed",
    JOIN_NOW = "join_now",
    JOIN_LATER = "join_later",
  }
  
  // Define the type for the log details which can be more structured if needed
  export type LogDetails = Record<string, any>; // You can specify the structure more strictly if needed
  
  export interface ActivityLog {
    action_type: ActionTypes;
    user_fk: number; // user ID (foreign key)
    other_details: LogDetails;
    ip_address: string;
}
  