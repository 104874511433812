import React from "react";
import { Typography, Space, Card, List, Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { UserRole } from "../types/users/userEnums";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const { Title, Paragraph } = Typography;

const AboutRewards: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.user);
  return (
    <Container>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {/* Reward Structure Section */}
        <StyledCard title="Basic Reward">
          <StyledList
            size="small"
            bordered
            dataSource={[
              "500 points for each referred client after completion of 5 cases.",
            ] as string[]} // Explicit type annotation
            renderItem={(item, index) => <List.Item key={index}>{item as string}</List.Item>}
          />
        </StyledCard>

        {/* Bonus Rewards (Distributive Partners) */}
        {[UserRole.CLIENT_ID_REFERRER, UserRole.DISTRIBUTOR].includes(user?.role) && (
            <StyledCard title="Bonus Rewards">
                {/* <Paragraph>
                <HighlightText>Only for Distributive Partners:</HighlightText>
                </Paragraph> */}
                <StyledList
                size="small"
                bordered
                dataSource={[
                    "2500 points for each client completing 100 CT/MRI cases (or 500 X-ray cases) within 30 days of onboarding.",
                    "10,000 points for each client completing 500 CT/MRI cases (or 2500 X-ray cases) within 30 days.",
                    "25,000 points for each client completing 1000 CT/MRI cases (or 5000 X-ray cases) within 30 days.",
                ] as string[]}
                renderItem={(item, index) => <List.Item key={index}>{item as string}</List.Item>}
                />
            </StyledCard>
        )}

        {/* FAQ Section */}
        <Title level={3} style={{ textAlign: "left" }}>FAQ</Title>
        <StyledCollapse
            accordion
            items={faqData.map(({ key, question, answer }) => ({
                key,
                label: question,
                children: <StyledParagraph>{answer}</StyledParagraph> 
            }))}
        />
      </Space>
    </Container>
  );
};

export default AboutRewards;


// 🔹 FAQ Data
const faqData = [
  {
    key: "1",
    question: "What is the 'Refer & Earn' program?",
    answer:
      "The 'Refer & Earn' program allows you to refer hospitals or diagnostic centers to 5C Network. If your referral is successfully onboarded and completes at least 5 cases, you will earn exciting rewards.",
  },
  {
    key: "2",
    question: "How can I refer to a hospital or diagnostic center?",
    answer:
      "Fill out the form with the required details. If you agree to share your details with the referred hospital/diagnostic center, tick the checkbox. If not, leave it unchecked and submit your referral.",
  },
  {
    key: "3",
    question: "What are the conditions to earn rewards?",
    answer:
      "To earn rewards, the hospital or diagnostic center must be successfully onboarded onto the 5C Network platform and complete at least 5 actual cases (demo cases excluded).",
  },
  {
    key: "4",
    question: "How do I track the status of my referral?",
    answer:
      "You will receive updates on your referral status at each stage, including referral submission confirmation, onboarding status, and notification when your reward is ready.",
  },
  {
    key: "5",
    question: "How long does it take to receive my reward?",
    answer:
      "Once the referred hospital is onboarded and completes 5 cases, the reward will be processed and sent to you within 7 working days.",
  },
];

// 🔹 Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 45px;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    max-width: 700px;
    padding: 40px;
  }

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 30px;
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    border-radius: 15px;
  }

  @media (max-width: 480px) {
    border-radius: 10px;
  }
`;

const StyledList = styled(List)`
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 8px;
  }

  @media (max-width: 480px) {
    border-radius: 6px;
  }
`;

const HighlightText = styled.span`
  background-color: #e70000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 3px 6px;
    font-size: 12px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  background: #ebf4ff;
  border-radius: 15px;
  padding: 10px;

  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    border-radius: 10px;
    padding: 6px;
  }
`;

const StyledCollapse = styled(Collapse).attrs({
  expandIconPosition: "end",
  expandIcon: ({ isActive }) => (
    <ExpandIconContainer>
      {isActive ? <MinusOutlined /> : <PlusOutlined />}
    </ExpandIconContainer>
  ),
})`
  .ant-collapse-item {
    border-radius: 10px;

    @media (max-width: 768px) {
      border-radius: 8px;
    }

    @media (max-width: 480px) {
      border-radius: 6px;
    }
  }
`;

const ExpandIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #000;
  position: absolute;
  top: 10px;
  right: 10px;

  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
    font-size: 9px;
    top: 8px;
    right: 8px;
  }

  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    font-size: 8px;
    top: 6px;
    right: 6px;
  }
`;
