import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { store } from "./redux/store"; // Ensure correct path to store
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// Add Google Fonts link tag inside React component
// const GoogleFontsLink = () => (
//   <link
//     href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap"
//     rel="stylesheet"
//   />
// );

root.render(
  // <React.StrictMode>
     <Provider store={store}>
      {/* <GoogleFontsLink /> */}
        <App />
    </Provider>
  // </React.StrictMode>
);

reportWebVitals();
