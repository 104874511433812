import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useAppDispatch } from "../redux/utils/hook";
import { getUserDetails } from "../redux/users/usersSlice";
import styled from "styled-components";
import Logo from "../assets/logo.svg";
import gupshup  from "../api/gupshupApi";
import { logUserActivity } from "../redux/activity_logs/activityLogsSlice";
import { ActionTypes, LogDetails } from "../redux/activity_logs/activityLogsTypes";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {

  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [otpSent, timer]);

  const handleSendOtp = async () => {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }

    setError("");
    const other_details: LogDetails = { platform: 'Web' };
    try {
      const generatedOtp = generateOtp(); // Function to generate OTP
      const otpSentSuccessfully = await gupshup.sendGupshupWA(phoneNumber, [generatedOtp, generatedOtp]);
      if (otpSentSuccessfully) {
        setOtpSent(true);
        localStorage.setItem("otp", generatedOtp); // Store OTP in localStorage for verification
        localStorage.setItem("phoneNumber", phoneNumber); // Store phone number in localStorage
        dispatch(logUserActivity({action_type: ActionTypes.OTP_SEND, other_details }))
      } else {
        dispatch(logUserActivity({action_type: ActionTypes.OTP_FAILED, other_details }))
        setError("Error sending OTP via WhatsApp. Please try again.");
      }
    } catch (error) {
      dispatch(logUserActivity({action_type: ActionTypes.OTP_FAILED, other_details }))
      setError("Error sending OTP. Please try again.");
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Random 6-digit OTP
  };

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join("");
    const storedOtp = localStorage.getItem("otp");
    if (enteredOtp === storedOtp) {
      const user = await dispatch(getUserDetails(phoneNumber)).unwrap();
      console.log(user);
      console.log('check==>>', Boolean(user) && user?.is_verified === "true");
      
      if (Boolean(user) && user?.is_verified === "true") {
        localStorage.removeItem("otp");
        const other_details: LogDetails = { platform: 'Web' };
        dispatch(logUserActivity({action_type: ActionTypes.LOGIN, other_details }))
        navigate("/home"); // If user exists and verified, navigate to home
      } else {
        navigate("/signup", { state: { ...user, mobile_number: phoneNumber } }); // If no user, navigate to signup
      }
    } else {
      setError("Invalid OTP. Please try again.");
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""]);
    setTimer(60);
    setError("");
    handleSendOtp();
  };

  return (
    <Container>
      <Row gutter={0} style={{ flex: 1, height: "100%" }}>
        <LeftColumn xs={24} sm={24} md={12} lg={12} xl={12}>
          <EllipseTopRight />
          <EllipseTopBottom />
          <LogoImage src={Logo} alt="Logo" />
          <Heading>
            {otpSent
              ? "5C Network is transforming diagnostics with AI. Verify now and be part of the change!"
              : "Join the 5C Network and be part of India’s leading AI-powered diagnostics revolution!"}
          </Heading>
        </LeftColumn>

        <RightColumn xs={24} sm={24} md={12} lg={12} xl={12}>
          <CardContainer>
            <Title>{otpSent ? "Enter confirmation code" : "Login"}</Title>
            <Subtitle>
              {otpSent
                ? `A 6-digit code was sent to ${phoneNumber}`
                : "Welcome! Just enter your mobile number to begin"}
            </Subtitle>

            {!otpSent ? (
              <>
                <Label>Mobile Number</Label>
                <Input
                  type="text"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {error && <ErrorText>{error}</ErrorText>}
                <div id="recaptcha-container"></div>
                <Button onClick={handleSendOtp}>Login</Button>
              </>
            ) : (
              <>
                <Label>Enter OTP</Label>
                <OtpContainer>
                  {otp.map((digit, index) => (
                    <OtpInput
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </OtpContainer>
                {timer > 0 ? (
                  <Subtitle>Resend OTP in {timer}s</Subtitle>
                ) : (
                  <ResendButton onClick={handleResendOtp}>Resend OTP</ResendButton>
                )}
                {error && <ErrorText>{error}</ErrorText>}
                <Button onClick={handleVerifyOtp} disabled={otp.some((digit) => digit === "")}>
                  Verify
                </Button>
              </>
            )}
          </CardContainer>
        </RightColumn>
      </Row>
    </Container>
  );
};

export default Login;

// Styled Components
const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 8px;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 12px;
    text-align: center;
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftColumn = styled(Col)`
  background-color: #0d1117;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 60px;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  text-align: left;

  @media (max-width: 768px) {
    height: 45vh;
    padding: 30px;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;


const RightColumn = styled(Col)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    height: auto;
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;


const LogoImage = styled.img`
  height: 40px;
  width: 120px;
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    height: 40px;
    width: 100px;
    top: 15px;
    left: 15px;
  }

  @media (max-width: 480px) {
    width: 80px;
    top: 10px;
    left: 10px;
  }
`;


const Heading = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  font-family: "Plus Jakarta Sans";
  margin-top: 80px;

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;


const CardContainer = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 16px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 12px;
    border-radius: 6px;
  }
`;


const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;


const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 13px;
    text-align: left;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 12px;
  }
`;


const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  display: block;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;


const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 13px;
    border-radius: 4px;
  }
`;


const Button = styled.button<{ disabled?: boolean }>`
  background: #0a2fb6;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: 100%;
  box-sizing: border-box;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  margin-top: 20px;
  transition: background 0.3s ease-in-out, transform 0.2s ease;

  &:hover {
    background: ${({ disabled }) => (disabled ? "#0a2fb6" : "#082794")};
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
  }
`;


const OtpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
    justify-content: center;
  }

  @media (max-width: 480px) {
    gap: 6px;
    margin-bottom: 10px;
  }
`;


const OtpInput = styled.input`
  width: 16%;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 14%;
    padding: 10px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    width: 12%;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
  }
`;


const ResendButton = styled.button`
  background: transparent;
  color: #0a2fb6;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #082794;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;


const EllipseTopRight = styled.div`
  position: fixed;
  top: -300px;
  right: 400px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 60%;

  @media (max-width: 1024px) {
    top: -250px;
    right: 300px;
    width: 350px;
    height: 350px;
    border: 80px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    top: -200px;
    right: 200px;
    width: 300px;
    height: 300px;
    border: 60px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 480px) {
    top: -150px;
    right: 100px;
    width: 200px;
    height: 200px;
    border: 40px solid rgba(255, 255, 255, 0.1);
  }
`;


const EllipseTopBottom = styled.div`
  position: fixed;
  bottom: -350px;
  left: -350px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 60%;

  @media (max-width: 1024px) {
    bottom: -300px;
    left: -300px;
    width: 350px;
    height: 350px;
    border: 80px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    bottom: -250px;
    left: -200px;
    width: 300px;
    height: 300px;
    border: 60px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 480px) {
    bottom: -200px;
    left: -100px;
    width: 200px;
    height: 200px;
    border: 40px solid rgba(255, 255, 255, 0.1);
  }
`;

