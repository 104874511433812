import { Route, Navigate } from "react-router-dom";
import React from "react";

// ProtectedRoute component to guard routes
interface ProtectedRouteProps {
    element: React.ReactNode;  // The component or JSX element to render
  }
  
  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const phoneNumber = localStorage.getItem("phoneNumber"); // Assuming you check user in localStorage

  // If the user is not authenticated (no user found in localStorage), redirect to login page
  // If the user is not authenticated, redirect to login page
  if (!phoneNumber) {
    return <Navigate to="/login" replace />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
