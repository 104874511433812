import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { N8N_BASE_URL, YAAKE_AUTH_TOKEN, YAAKE_BASE_URL } from "../../utils/constants";
import { RootState } from "../store";
import AxiosInstance from "../../services/AxiosInstance";
import { getAPIEndPoints } from "../../utils/api";

// Thunk action to send create user slack notification
export const createUserNotification = createAsyncThunk<any , any>(
    "notifications/createUserNotification",
    async (data, { rejectWithValue }) => {
      const {
        first_name, 
        last_name, 
        mobile_number, 
        email, 
        workplace, 
        position,
        client_fk,
      } = data;
  
      const notificationPayload = {
        "name": `${first_name} ${last_name}`,
        "phone": mobile_number,
        email,
        workplace,
        position,
        client_fk
      }
      
      try {
          const response: any = await AxiosInstance.post(getAPIEndPoints.slackNotifications.createUser(), {
              baseURL: N8N_BASE_URL,
              data: notificationPayload,
          });
  
          if (!response?.ok) {
              throw new Error("Failed to send create user notification");
          }
          return response;
      } catch (error: any) {
        return rejectWithValue(error?.message);
      }
    }
);

// Thunk action to send create referral slack notification
export const createReferralNotification = createAsyncThunk<any , any>(
  "notifications/createReferralNotification",
  async (data, { getState, rejectWithValue }) => {
    const {
        referred_mobile_number, 
        referred_email, 
        referred_name, 
        organisation_name, 
        organisation_type, 
        share_personal_info
    } = data;
    
    const state = getState() as RootState;
    const { user } = state?.user;
    const {first_name, last_name, mobile_number} = user;

    const notificationPayload = {
        "phone": referred_mobile_number,
        "email": referred_email,
        "name": referred_name,
        "share_personal_info": share_personal_info === "true" ? "Yes" : "No",
        "client_info":{
            "name": organisation_name,
            "type": organisation_type
        },
        "sender":{
            "name": `${first_name} ${last_name}`,
            "phone": mobile_number
        }
    }
    
    try {
        const response: any = await AxiosInstance.post(getAPIEndPoints.slackNotifications.createReferral(), {
            baseURL: N8N_BASE_URL,
            data: notificationPayload,
        });

        if (!response?.ok) {
            throw new Error("Failed to send referral notification");
        }
        return response;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

// Thunk action to send create referral yaake notification
export const createReferralYaakeNotification = createAsyncThunk<any , any>(
    "notifications/createReferralYaakeNotification",
    async (data, {getState, rejectWithValue }) => {
        const {
            referred_mobile_number, 
            referred_email, 
            referred_name, 
            organisation_name, 
            organisation_type, 
            share_personal_info
        } = data;
        
        const state = getState() as RootState;
        const { user } = state?.user;
        const { Id, first_name, last_name, mobile_number } = user;

        const notificationPayload = {
            "phone": referred_mobile_number,
            "email": referred_email,
            "name": referred_name,
            "share_personal_info": share_personal_info,
            "client_info":{
                "name": organisation_name,
                "type": organisation_type
            },
            "sender":{
                "name": `${first_name} ${last_name}`,
                "phone": mobile_number,
                "id": Id
            }
        }
        try {
            const response: any = await AxiosInstance.post(getAPIEndPoints.yaakeNotifications.createReferrals(), {
                baseURL: YAAKE_BASE_URL,
                data: notificationPayload,
                headers: { 
                    "Content-Type": "application/json", 
                    "Authorization": YAAKE_AUTH_TOKEN
                }
            });
    
            if (!response.ok) {
                throw new Error("Failed to send create referral yaake notification");
            }
    
            return response;
        } catch (error: any) {
            return rejectWithValue(error?.message);
        }
    }
);

// Thunk action to send distributor role request slack notification
export const requestForDistributorRoleNotification = createAsyncThunk<any , any>(
    "notifications/requestForDistributorRoleNotification",
    async (_, { getState, rejectWithValue }) => {

      const state = getState() as RootState;
      const { user } = state?.user;
      const {first_name, last_name, mobile_number} = user;
  
      const notificationPayload = {
        "name": `${first_name} ${last_name}`,
        "phone": mobile_number
      }
      
      try {
          const response: any = await AxiosInstance.post(getAPIEndPoints.slackNotifications.requestForDistributorRole(), {
              baseURL: N8N_BASE_URL,
              data: notificationPayload,
          });
  
          if (!response?.ok) {
            throw new Error("Failed to send distributor request notification");
          }
          return response;
      } catch (error: any) {
        return rejectWithValue(error?.message);
      }
    }
);

// Thunk action to send redeem request slack notification
export const requestForRedeemNotification = createAsyncThunk<any , any>(
    "notifications/requestForRedeemNotification",
    async (data, { getState, rejectWithValue }) => {
      const { amount, redeem_type } = data;
      const state = getState() as RootState;
      const { user } = state?.user;
      const {first_name, last_name, mobile_number} = user;
  
      const notificationPayload = {
        "name": `${first_name} ${last_name}`,
        "phone": mobile_number,
        amount,
        redeem_type
      }
      
      try {
          const response: any = await AxiosInstance.post(getAPIEndPoints.slackNotifications.createRedeem(), {
              baseURL: N8N_BASE_URL,
              data: notificationPayload,
          });
  
          if (!response?.ok) {
            throw new Error("Failed to send redeem request notification");
          }
          return response;
      } catch (error: any) {
        return rejectWithValue(error?.message);
      }
    }
);

const { actions, reducer } = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder

    //================================================================//
      .addCase(createReferralNotification.pending, (state) => {
      })
      .addCase(createReferralNotification.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(createReferralNotification.rejected, (state, action) => {
      })
      
      //================================================================//
      .addCase(createReferralYaakeNotification.pending, (state) => {
      })
      .addCase(createReferralYaakeNotification.fulfilled, (state, action) => {
        return action.payload; // Add the new notification to the state
      })
      .addCase(createReferralYaakeNotification.rejected, (state, action) => {
      }) 

    //================================================================//
        .addCase(createUserNotification.pending, (state) => {
        })
        .addCase(createUserNotification.fulfilled, (state, action) => {
        return action.payload;
        })
        .addCase(createUserNotification.rejected, (state, action) => {
        })

                    //================================================================//
      .addCase(requestForDistributorRoleNotification.pending, (state) => {
      })
      .addCase(requestForDistributorRoleNotification.fulfilled, (state, action) => {
        return action.payload; 
      })
      .addCase(requestForDistributorRoleNotification.rejected, (state, action) => {
      })

                          //================================================================//
                          .addCase(requestForRedeemNotification.pending, (state) => {
                          })
                          .addCase(requestForRedeemNotification.fulfilled, (state, action) => {
                            return action.payload; 
                          })
                          .addCase(requestForRedeemNotification.rejected, (state, action) => {
                          });
  },
});

export const {} = actions;
export default reducer;