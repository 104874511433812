export const enum UserRole {
  REFERRER = "referrer",
  DISTRIBUTOR = "distributor",
  CLIENT_ID_REFERRER = "client_id_referrer",
}

export const enum DistributorStatus {
  REQUESTED = "requested",
  ACCEPTED = "accepted"
}

export const enum UserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
}
