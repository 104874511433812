import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../services/AxiosInstance";
import { getAPIEndPoints } from "../../utils/api";
import { RootState } from "../store"; // Import RootState
import { AppDispatch } from "../store";
import { createReferralNotification, createReferralYaakeNotification} from "../notifications/notificationsSlice";

// Initial State
interface ReferralsState {
  referrals: any[];
  total: number;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState: ReferralsState = {
  referrals: [],
  total: 0,
  loading: false,
  error: null,
  success: false,
};

// **Thunk: Create a New Referral**
export const createReferral = createAsyncThunk<any, any, {dispatch: AppDispatch}>(
  "createReferral",
  async (data, { dispatch, rejectWithValue }) => {
    try {
        let query = `(referred_mobile_number,eq,${data?.referred_mobile_number})`;
        if(data?.referred_email){
            query = query + `~or(referred_email,eq,${data?.referred_email})`;
        }
        const response = await AxiosInstance.get(getAPIEndPoints.referrals(), {
            params: { where: query }
        });
        const isAlreadyExist = Boolean(response.data.list.length);
        if(isAlreadyExist){
            return rejectWithValue("Referral has been already created with mobile number or email.");
        }
        await AxiosInstance.post(getAPIEndPoints.referrals(), {
            data,
        });

        dispatch(createReferralYaakeNotification(data))
        dispatch(createReferralNotification(data));
        return await dispatch(getReferralsList({ page: 1 }));
    } catch (error) {
      return rejectWithValue("Failed to submit referral.");
    }
  }
);

// **Thunk: Fetch Referrals List Based on User**
export const getReferralsList = createAsyncThunk<
  { referrals: any[]; total: number },
  { page: number },
  { state: RootState }
>("getReferralsList", async ({ page }, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const userId = state.user.user?.Id;
        if (!userId) throw new Error("User ID not found");

        const limit = 10; // Fixed limit
        const offset = (page - 1) * limit; // Offset calculation

        // 🔹 Fetch Referrals with Pagination
        const referralQuery = `(user_fk,eq,${userId})`;
        const referralResponse = await AxiosInstance.get(getAPIEndPoints.referrals(), {
            params: { where: referralQuery, limit, offset },
        });

        const referrals = referralResponse.data.list;
        const total = referralResponse.data.pageInfo?.totalRows || 0; // Extract total count

        // 🔹 Fetch Rewards
        const rewardQuery = `(user_fk,eq,${userId})~and(status,eq,created)`;
        const rewardResponse = await AxiosInstance.get(getAPIEndPoints.rewards(), {
            params: { where: rewardQuery },
        });
        const rewards = rewardResponse.data.list;

        // 🔹 Map & Sum Rewards for Each Referral
        const referralMap = referrals.map((referral: any) => {
            const referralRewards = rewards
                .filter((reward: any) => reward.referral_fk === referral.Id)
                .reduce((sum: number, reward: any) => sum + reward.amount, 0);

            return { ...referral, reward: referralRewards };
        });

        return { referrals: referralMap, total }; // Return total from pageInfo
    } catch (error) {
        return rejectWithValue("Failed to fetch referrals.");
    }
});


// **Referral Slice**
const { actions, reducer } = createSlice({
  name: "referrals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Referral
      .addCase(createReferral.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReferral.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createReferral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch Referrals List Based on User
      .addCase(getReferralsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferralsList.fulfilled, (state, action) => {
        state.referrals = action.payload.referrals;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(getReferralsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

    //     // Send Referral Notification on slack
    //   .addCase(sendReferralToN8N.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //     state.success = false;
    //   })
    //   .addCase(sendReferralToN8N.fulfilled, (state) => {
    //     state.loading = false;
    //     state.success = true;
    //   })
    //   .addCase(sendReferralToN8N.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload as string;
    //   });
  },
});

export const {} = actions;
export default reducer;
