import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../services/AxiosInstance";
import { getAPIEndPoints } from "../../utils/api";
import { RootState } from "../store";
import moment from "moment";

interface RewardState {
  total_rewards: number;
  rewards: any[];
  loading: boolean;
  error: string | null;
}

const initialState: RewardState = {
  total_rewards: 0,
  rewards: [],
  loading: false,
  error: null,
};

// 🔹 **Fetch Total Rewards for Logged-in User**
export const getTotalRewards = createAsyncThunk(
  "rewards/getTotalRewards",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.user.user?.Id;

      if (!userId) return rejectWithValue("User not found");

      const query = `(user_fk,eq,${userId})~and(status,eq,created)`;
      const response = await AxiosInstance.get(getAPIEndPoints.rewards(), {
        params: { where: query },
      });

      // 🔢 Sum total rewards
      const totalRewards = response.data.list.reduce(
        (sum: number, reward: any) => sum + reward.amount,
        0
      );

      return { total_rewards: totalRewards, rewards: response.data.list };
    } catch (error) {
      return rejectWithValue("Failed to fetch rewards");
    }
  }
);

// 🔹 **Bulk Update Rewards Status (Used in Redeem Process)**
export const updateRewardsStatus = createAsyncThunk(
  "rewards/updateRewardsStatus",
  async ({ rewardsToUpdate, createdRedeemId }: { rewardsToUpdate: any[], createdRedeemId: string }, { rejectWithValue }) => {
    try {
      // Updating rewards in bulk
      await AxiosInstance.patch(getAPIEndPoints.rewards(), {
        data: rewardsToUpdate.map((reward) => ({
          Id: reward.Id,
          status: "redeemed",
          redeemed_at: moment().format("YYYY-MM-DD"),
          redeem_fk: createdRedeemId,
        })),
      });

      // Update local state
      return rewardsToUpdate.map((reward) => ({
        ...reward,
        status: "redeemed",
        redeemed_at: moment().format("YYYY-MM-DD"),
        redeem_fk: createdRedeemId,
      }));
    } catch (error) {
      return rejectWithValue("Failed to update rewards.");
    }
  }
);

// 🎯 **Rewards Slice**
const rewardSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTotalRewards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTotalRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.total_rewards = action.payload.total_rewards;
        state.rewards = action.payload.rewards;
      })
      .addCase(getTotalRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // 🔹 Update Rewards Status
      .addCase(updateRewardsStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRewardsStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.rewards = state.rewards.map((reward) =>
          action.payload.find((updated) => updated.Id === reward.Id) || reward
        );
      })
      .addCase(updateRewardsStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default rewardSlice.reducer;
