export const NOCO_DB_BASE_URL='https://answermagic.5cn.co.in/';
export const NOCO_DB_API_XC_TOKEN='yijdLJp7Lv0HqySngP_bYlaHm6zF6h0ogZbs6bjv';
export const N8N_WEBHOOK_URL = "https://groot.5cn.co.in/webhook/create-referral";
export const N8N_BASE_URL = "https://groot.5cn.co.in/webhook";
export const YAAKE_BASE_URL = "https://yaake-backend.cubebase.ai";
export const YAAKE_AUTH_TOKEN = "AD4k09EWQR908FEWRQFwsRWQ";
export const GUPSHUP_API_BASE_URL = 'https://api.gupshup.io';
export const GUPSHUP_API_ENDPOINT = '/wa/api/v1/template/msg'
export const GUPSHUP_SOURCE_NUMBER = '919900794672';
export const GUPSHUP_SOURCE_NAME = 'JG05QalEVANF3vMzwg2XOoZY';
export const GUPSHUP_API_KEY= 'sjdhvld1gidlru2f3nwxp9gcn223nwad';
export const GUPSHUP_OTP_TEMPLATE_ID = '207686a9-ec88-40ce-a24a-c3b50e80fc69';