import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, message, Select, Input, Button } from "antd";
import styled from "styled-components";
import Logo from "../assets/logo.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { createUser, updateUserDetails } from "../redux/users/usersSlice";
import { createUserNotification } from "../redux/notifications/notificationsSlice";
import { ActionTypes, LogDetails } from "../redux/activity_logs/activityLogsTypes";
import { logUserActivity } from "../redux/activity_logs/activityLogsSlice";

const { Option } = Select;

const Signup = () => {
  const positions = [
    "Doctor",
    "Radiologist",
    "Nurse",
    "Medical Technician",
    "Administrator",
    "Manager",
    "Support Staff",
    "Marketing/Business Development",
    "Other", // This will trigger the input field
  ];
  
  const [showOther, setShowOther] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(location.state?.Id){
      const user = location.state;
      form.setFieldsValue({
        name: `${user.first_name} ${user.last_name}`,
        mobile_number: user.mobile_number,
        email: user.email,
        workplace: user.workplace,
        positionType: positions.includes(user.position) ? user.position : "Other",
        position: positions.includes(user.position) ? "" : user.position,
      });
      setShowOther(!positions.includes(user.position));
      return;
    }
    
    if (location.state?.mobile_number) {
      form.setFieldsValue({ mobile_number: location.state.mobile_number });
    }
  }, [location.state]);

  const handlePositionChange = (value: string) => {
    setShowOther(value === "Other");
    form.setFieldsValue({ position: value === "Other" ? "" : value });
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const nameParts = values.name.trim().split(" ");
      const first_name = nameParts[0];
      const last_name = nameParts.slice(1).join(" ") || "";

      const updatedUser:any = {
        first_name,
        last_name,
        mobile_number: values.mobile_number,
        email: values.email,
        workplace: values.workplace,
        position: showOther ? values.position : values.positionType,
        is_verified: "true"
      };

      if(location.state?.Id){
        updatedUser.Id = location.state.Id;
        await dispatch(updateUserDetails(updatedUser)).unwrap();
      }else{
        await dispatch(createUser(updatedUser)).unwrap();
      }
      message.success("Profile created successfully!");
      form.resetFields();
      if(location.state?.client_fk){
        updatedUser.client_fk = location.state.client_fk;
      }
      dispatch(createUserNotification(updatedUser))
      const other_details: LogDetails = { platform: 'Web' };
      dispatch(logUserActivity({action_type: ActionTypes.LOGIN, other_details }))
      navigate("/home");
    } catch (error: any) {
      message.error(error || "Failed to create profile. Email or mobile might already exist.");
    }
    setLoading(false);
  };

  return (
    <Container>
      <Row gutter={0} style={{ flex: 1, height: "100%" }}>
        <LeftColumn xs={24} sm={24} md={12} lg={12} xl={12}>
          <EllipseTopRight />
          <EllipseTopBottom />
          <LogoImage src={Logo} alt="Logo" />
          <Heading>
            Almost there! Complete your account creation and join 5C Network—shaping the future of diagnostics with AI!
          </Heading>
        </LeftColumn>

        <RightColumn xs={24} sm={24} md={12} lg={12} xl={12}>
        <FormContainer>
          <h1 style={{ color: "#0A2FB6", textAlign: "center", marginBottom: "20px", fontSize: "24px" }}>
            Create Your Account
          </h1>
          <StyledForm
            form={form}
            layout="vertical"
            labelAlign="left"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleSubmit}
          >
            {/* Name */}
            <Form.Item
              name="name"
              label={<FormItemLabel>Name</FormItemLabel>}
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <StyledInput placeholder="Enter your name" />
            </Form.Item>

            {/* Mobile Number */}
            <Form.Item name="mobile_number" label="Mobile Number" rules={[{ required: true, message: "Please enter your mobile number" }]}>
              <StyledInput placeholder="Enter your mobile number" maxLength={10} disabled />
            </Form.Item>

            {/* Email */}
            <Form.Item
              name="email"
              label={<FormItemLabel>Email</FormItemLabel>}
              rules={[{ type: "email", message: "Enter a valid email" }]}
            >
              <StyledInput placeholder="Enter email (optional)" />
            </Form.Item>

            {/* Workplace & Position Fields */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="workplace"
                  label={<FormItemLabel>Workplace</FormItemLabel>}
                  rules={[{ required: true, message: "Please enter your workplace" }]}
                >
                  <StyledInput placeholder="Enter your workplace" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="positionType"
                  label={<FormItemLabel>Position</FormItemLabel>}
                  rules={[{ required: true, message: "Please select your position" }]}
                >
                  <StyledSelect
                    placeholder="Select your position"
                    onChange={(value) => handlePositionChange(value as string)}
                  >
                    {positions.map((position) => (
                      <Option key={position} value={position}>
                        {position}
                      </Option>
                    ))}
                  </StyledSelect>
                </Form.Item>

                {/* Show input field if "Other" is selected */}
                {showOther && (
                  <Form.Item
                    name="position"
                    label={<FormItemLabel>Specify Position</FormItemLabel>}
                    rules={[{ required: true, message: "Please enter your position" }]}
                  >
                    <StyledInput placeholder="Enter your position" />
                  </Form.Item>
                )}
              </Col>
            </Row>

            {/* Submit Button */}
            <Form.Item>
              <SubmitButton htmlType="submit" type="primary" loading={loading}>
                Join Now
              </SubmitButton>
            </Form.Item>
          </StyledForm>
          </FormContainer>
        </RightColumn>
      </Row>
    </Container>
  );
};

export default Signup;

// 🔹 Styled Components
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftColumn = styled(Col)`
  background-color: #0d1117;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 60px;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  text-align: left;

  @media (max-width: 768px) {
    height: 45vh;
    padding: 30px;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const RightColumn = styled(Col)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    height: auto;
    padding: 40px;
  }

  @media (max-width: 480px) {
    padding: 30px;
  }
`;

const LogoImage = styled.img`
  height: 40px;
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    height: 40px;
    top: 15px;
    left: 15px;
  }

  @media (max-width: 480px) {
    top: 10px;
    left: 10px;
  }
`;

const Heading = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  font-family: "Plus Jakarta Sans";
  margin-top: 80px;

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;

const FormContainer = styled.div`
  min-width: 80%;
  margin: 0 auto;
  padding: 45px;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    min-width: 70%;
    padding: 35px;
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    min-width: 60%;
    padding: 25px;
    border-radius: 20px;
  }
`;


const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adds spacing between form items */

  @media (max-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const FormItemLabel = styled.label`
    font-size: 14px;
    font-weight: 600;
    color: #444;
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-tooltip {
    font-size: 10px;
    }    
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    height: 38px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 36px;
    font-size: 14px;
  }
`;

const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    height: 38px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 36px;
    font-size: 14px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  height: 50px;
  background: #0a2fb6;
  border-radius: 60px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #082794;
  }

  @media (max-width: 768px) {
    height: 45px;
    font-size: 15px;
    border-radius: 50px;
  }

  @media (max-width: 480px) {
    height: 40px;
    font-size: 14px;
    border-radius: 40px;
  }
`;

const EllipseTopRight = styled.div`
  position: fixed;
  top: -300px;
  right: 400px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 60%;

  @media (max-width: 1024px) {
    top: -250px;
    right: 300px;
    width: 350px;
    height: 350px;
    border: 80px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    top: -200px;
    right: 200px;
    width: 300px;
    height: 300px;
    border: 60px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 480px) {
    top: -150px;
    right: 100px;
    width: 200px;
    height: 200px;
    border: 40px solid rgba(255, 255, 255, 0.1);
  }
`;

const EllipseTopBottom = styled.div`
  position: fixed;
  bottom: -350px;
  left: -350px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 60%;

  @media (max-width: 1024px) {
    bottom: -300px;
    left: -300px;
    width: 350px;
    height: 350px;
    border: 80px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    bottom: -250px;
    left: -200px;
    width: 300px;
    height: 300px;
    border: 60px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 480px) {
    bottom: -200px;
    left: -100px;
    width: 200px;
    height: 200px;
    border: 40px solid rgba(255, 255, 255, 0.1);
  }
`;
