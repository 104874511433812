import { UserRole, DistributorStatus } from "./userEnums"; // Importing Enums

export interface User {
  Id: string | null;
  first_name: string;
  last_name: string;
  mobile_number: string;
  email: string;
  workplace: string;
  position: string;
  role: UserRole;
  distributor_status: DistributorStatus | null;
  total_rewards: number;
  is_verified: string;
}

export interface UserState {
  user: User;
  loading: boolean;
  error: string | null;
  isFetching: boolean;
}

export const initialState: UserState = {
  user: {
    Id: null,
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    workplace: "",
    position: "",
    role: UserRole.REFERRER,
    distributor_status: null,
    total_rewards: 0,
    is_verified: "false",
  },
  loading: false,
  error: null,
  isFetching: true,
};
