import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../services/AxiosInstance";
import { getAPIEndPoints } from "../../utils/api";
import { getTotalRewards, updateRewardsStatus } from "../rewards/rewardsSlice";
import { RootState } from "../store";
import { requestForRedeemNotification } from "../notifications/notificationsSlice";

interface RedeemState {
  redeems: any[];
  loading: boolean;
  error: string | null;
}

const initialState: RedeemState = {
  redeems: [],
  loading: false,
  error: null,
};

// 🔹 **Create Redeem and Update Rewards**
export const createRedeemAndUpdateRewards = createAsyncThunk(
  "redeems/createRedeemAndUpdateRewards",
  async (redeemType: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.user.user?.Id;
      const rewardsToUpdate = state.rewards.rewards.filter((reward) => reward.status === "created");

      if (!userId) return rejectWithValue("User not found");
      if (rewardsToUpdate.length === 0) return rejectWithValue("No rewards available for redemption.");

      // ✅ Step 1: Create Redeem Entry
      const redeemResponse = await AxiosInstance.post(getAPIEndPoints.redeems(), {
        data: { user_fk: userId, amount: state.rewards.total_rewards, redeem_type: redeemType },
      });

      const createdRedeemId = redeemResponse.data.Id;

      // ✅ Step 2: Call `updateRewardsStatus` thunk
      await dispatch(updateRewardsStatus({ rewardsToUpdate, createdRedeemId }));

        // ✅ Step 3: Refresh total rewards
        await dispatch(getTotalRewards());
        dispatch(requestForRedeemNotification({amount: state.rewards.total_rewards, redeem_type: redeemType}))
      return redeemResponse.data;
    } catch (error) {
      return rejectWithValue("Failed to create redeem request.");
    }
  }
);

// 🎯 **Redeems Slice**
const redeemsSlice = createSlice({
  name: "redeems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRedeemAndUpdateRewards.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRedeemAndUpdateRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.redeems.push(action.payload);
      })
      .addCase(createRedeemAndUpdateRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default redeemsSlice.reducer;
