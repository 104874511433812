import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from "../../services/AxiosInstance";
import { RootState } from '../store';
import { getAPIEndPoints } from '../../utils/api';

// Define the initial state for logs
const initialState = {
  logs: [],
  loading: false,
  error: null,
};

// Helper function to get IP address
const getIPAddress = async () => {
    const storedIP = localStorage.getItem('user_ip');
    const storedTimestamp = localStorage.getItem('user_ip_timestamp');
  
    const IP_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  
    if (storedIP && storedTimestamp) {
      const timeDifference = Date.now() - parseInt(storedTimestamp);
      
      // If the IP is still valid (within 24 hours), return it
      if (timeDifference < IP_EXPIRY_TIME) {
        return storedIP;
      }
    }
  
    try {
      // Fetch the IP from the API if it's not found or expired
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ip = data.ip;
  
      // Store the IP and the current timestamp in localStorage
      localStorage.setItem('user_ip', ip);
      localStorage.setItem('user_ip_timestamp', Date.now().toString());
  
      return ip;
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  };
  

// Thunk to log user activities (login, OTP, popup, etc.)
export const logUserActivity = createAsyncThunk(
  'logs/logUserActivity',
  async ({ action_type, other_details } : { action_type: string, other_details: any }, { getState, rejectWithValue }) => {
    try {
        // Get the IP address asynchronously
        const ip_address = await getIPAddress();

        const state = getState() as RootState;
        const { user } = state.user;
        if (!user?.Id) throw new Error("User ID not found");

        // Save activity log
      const response = await AxiosInstance.post(getAPIEndPoints.logs(), {
        data: {
            user_fk: user?.Id,
            action_type,
            other_details,
            ip_address
          },
      });

      // Returning response data to be stored in Redux
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to save activity log');
    }
  }
);

// Create the slice for logs
const activityLogsSlice = createSlice({
  name: 'activityLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logUserActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(logUserActivity.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(logUserActivity.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default activityLogsSlice.reducer;
