import React, { useState, useEffect } from "react";
import { Layout, Tabs, Button, Card, message, Modal, Radio, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInitialData, updateUserDetails } from "../redux/users/usersSlice";
import Referral from "./Referral";
import Tracker from "./Tracker";
import AboutRewards from "./AboutRewards";
import Profile from "./Profile";
import Logo from "../assets/logo.svg";
import Coin from "../assets/coins.svg";
import VerifyIcon from "../assets/verify-filled.svg";
import styled from "styled-components";
import { AppDispatch, RootState } from "../redux/store";
import { createRedeemAndUpdateRewards } from "../redux/redeems/redeemsSlice";
import { UserRole } from "../types/users/userEnums";
import { getTotalRewards } from "../redux/rewards/rewardsSlice";
import { requestForDistributorRoleNotification } from "../redux/notifications/notificationsSlice";
import { ActionTypes, LogDetails } from "../redux/activity_logs/activityLogsTypes";
import { logUserActivity } from "../redux/activity_logs/activityLogsSlice";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("referral");
  const { user, loading } = useSelector((state: RootState) => state.user);
  const [redeemVisible, setRedeemVisible] = useState(false);
  const [redeemType, setRedeemType] = useState("");
  const { total_rewards } = useSelector(
    (state: RootState) => state.rewards
  );

  // Conditionally render "About Rewards" tab based on user role
  const tabs = [
    { key: "referral", label: "Referral Form", children: <Referral /> },
  ];
  // Add "Tracker" tab only for specific roles
  if ([UserRole.REFERRER, UserRole.CLIENT_ID_REFERRER,UserRole.DISTRIBUTOR].includes(user?.role)) {
    tabs.push({ key: "tracker", label: "Tracker", children: <Tracker /> });
  }
  // Add "About Rewards" tab only for specific roles
  if ([UserRole.CLIENT_ID_REFERRER, UserRole.DISTRIBUTOR].includes(user?.role)) {
    tabs.push({ key: "about-rewards", label: "About Rewards", children: <AboutRewards /> });
  }
  // In the last add "My Profile"
  tabs.push({ key: "profile", label: "My Profile", children: <Profile /> });

  useEffect(() => {
    dispatch(getInitialData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTotalRewards());
  }, [user?.Id, dispatch]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <Spin size="large" />
      </div>
    );
  }

    // If no user is found, redirect to login page
    if (!user) {
      navigate("/login", { replace: true });
      return null; // Prevent rendering the rest of the page
    }

  // Handle Logout
  const handleLogout = async () => {
    localStorage.removeItem("phoneNumber");
    const other_details: LogDetails = { platform: 'Web' };
    dispatch(logUserActivity({action_type: ActionTypes.LOGOUT, other_details }))
    navigate("/login", { replace: true });
  };

  const handleJoinAsPartner = async () => {
    try {
      await dispatch(updateUserDetails({ Id: user.Id, distributor_status: "requested" })).unwrap();
      message.success("Awesome. Request sent successfully. You will hear from us shortly!");
      dispatch(requestForDistributorRoleNotification({}))
    } catch (error) {
      message.error("Failed to send request.");
    }
  };

  const handleRedeem = async () => {
    if (!redeemType) {
      message.error("Please select a redeem option!");
      return;
    }

    try {
      await dispatch(createRedeemAndUpdateRewards(redeemType)).unwrap();
      message.success("You will get the selected rewards, and one of our team members will contact you shortly.");
      setRedeemVisible(false);
    } catch (error) {
      message.error("Failed to submit redeem request.");
    }
  };

  return (
    <LayoutWrapper>
      <HeaderWrapper>
        <EllipseTopRight />
        <LogoImage src={Logo} alt="Logo" />
        <LogoutButton type="primary" onClick={handleLogout}>
          Logout
        </LogoutButton>
      </HeaderWrapper>

      <UserInfoWrapper>
        <EllipseTopRight />
        <div>
          <UserNameWrapper>
            <h1>Hello, {user?.first_name} {user?.last_name}</h1>
            {user?.role === UserRole.DISTRIBUTOR && (
              <img src={VerifyIcon} alt="Verified" />
            )}
          </UserNameWrapper>
          <UserDescription>
            Good day! 🌟 Spread the word about 5C within your network and unlock exclusive rewards for every referral.          </UserDescription>
          {user?.role !== UserRole.DISTRIBUTOR && (
            user?.distributor_status === 'requested' ? (
              <JoinButton className="requested" size="large">
                Your Partner Request is Under Review!
              </JoinButton>
            ) : (
              <JoinButton type="primary" size="large" onClick={handleJoinAsPartner} loading={loading}>
                Join as our partner
              </JoinButton>
            )
          )}
        </div>
          {[UserRole.CLIENT_ID_REFERRER, UserRole.DISTRIBUTOR].includes(user?.role) && (
            <RewardCard>
              <CardEllipsisTop />
              <CardEllipsisBottom />
              <RewardPointsWrapper>
                <p>Reward Points</p>
                <RewardAmount>
                  <img src={Coin} alt="Coin" />
                  <h2>{total_rewards || 0}</h2>
                </RewardAmount>
              </RewardPointsWrapper>
              <RedeemButtonWrapper>
                <RedeemButton type="default" onClick={() => setRedeemVisible(true)} disabled={!total_rewards}>Redeem</RedeemButton>
              </RedeemButtonWrapper>
            </RewardCard>
          )}
      </UserInfoWrapper>

      <ContentWrapper>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          centered
          type="card"
          items={tabs}
        />
      </ContentWrapper>

      {/* Redeem Popup Modal */}
      <Modal
        title={<Title level={3}>Redeem Options</Title>}
        open={redeemVisible}
        onCancel={() => setRedeemVisible(false)}
        footer={null}
        centered
        width={400} // Reduced width
        style={{ borderRadius: "20px", overflow: "hidden" }} // Added border radius
      >
        <Text type="secondary">
          Total Reward Points: {total_rewards || 0} <img src={Coin} alt="Coin" style={{ width: "15px" }} />
        </Text>
        <Radio.Group
          onChange={(e) => setRedeemType(e.target.value)}
          value={redeemType}
          style={{ display: "flex", flexDirection: "column", gap: "12px", marginTop: "20px" }}
        >
          <Radio value="voucher">Vouchers</Radio>
          <Radio value="gift">5C Gifts</Radio>
          {user?.role === UserRole.CLIENT_ID_REFERRER && (
            <Radio value="discount">Discounts on Billing</Radio>
          )}
          {user?.role === UserRole.DISTRIBUTOR && (
            <Radio value="account_transfer">Account Transfer</Radio>
          )}
        </Radio.Group>
        <Button
          type="primary"
          block
          size="large"
          onClick={handleRedeem}
          style={{ marginTop: "20px", background: "#0A2FB6", borderRadius: "30px" }} // More rounded button
        >
          Submit
        </Button>
      </Modal>

    </LayoutWrapper>
  );
};

export default Home;

const LayoutWrapper = styled(Layout)`
  min-height: 100vh;
  background: #f5f5f5;

  @media (max-width: 768px) {
    min-height: auto;
    background: #ffffff;
  }

  @media (max-width: 480px) {
    padding-bottom: 20px;
  }
`;

const HeaderWrapper = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #001529;
  padding: 0 20px;
  height: 64px;

  @media (max-width: 768px) {
    padding: 0 15px;
    height: 56px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
    height: 50px;
  }
`;


const LogoImage = styled.img`
  height: 40px;

  @media (max-width: 768px) {
    height: 35px;
  }

  @media (max-width: 480px) {
    height: 30px;
  }
`;

const LogoutButton = styled(Button)`
  background: #0a2fb6;
  border: none;
  border-radius: 30px;
  z-index: 9999;
  padding: 10px 20px;
  font-size: 16px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #082794;
  }

  @media (max-width: 768px) {
    padding: 8px 18px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 6px 16px;
    font-size: 13px;
    border-radius: 25px;
  }
`;


const UserInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0d1b2a;
  color: #fff;
  padding: 80px 80px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 60px 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 30px 15px;
  }
`;

const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 64px;
    margin: 0;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 50px;
    }

    @media (max-width: 768px) {
      font-size: 44px;
      text-align: center;
    }

    @media (max-width: 480px) {
      text-align: center;
      font-size: 32px;
    }
  }

  img {
    width: 40px;
    margin-left: 10px;

    @media (max-width: 768px) {
      width: 33px;
      margin-left: 8px;
    }

    @media (max-width: 480px) {
      width: 28px;
      margin-left: 5px;
    }
  }
`;


const UserDescription = styled.p`
  font-size: 20px;
  opacity: 0.8;
  color: #90a3bf;
  max-width: 700px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 18px;
    max-width: 600px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 500px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

const JoinButton = styled(Button)`
  background: #0a2fb6;
  border: none;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 18px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #082794;
  }

  &.requested {
    background: transparent !important;
    border: 2px solid #fff !important;
    color: #fff !important;
    cursor: default;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 25px;
  }
`;


const RewardCard = styled(Card)`
  width: 220px;
  height: 280px;
  background: #ef4d51;
  color: #fff;
  text-align: left;
  border-radius: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 200px;
    height: 260px;
    border-radius: 40px;
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 240px;
    border-radius: 35px;
    text-align: center;
  }

  @media (max-width: 480px) {
    width: 160px;
    height: 220px;
    border-radius: 30px;
  }
`;


const CardEllipsisTop = styled.div`
  position: absolute;
  top: -120px;
  right: -120px;
  width: 250px;
  height: 250px;
  background: transparent;
  border-radius: 50%;
  border: 125px solid #000000;
  opacity: 10%;

  @media (max-width: 1024px) {
    top: -100px;
    right: -100px;
    width: 200px;
    height: 200px;
    border: 100px solid #000000;
  }

  @media (max-width: 768px) {
    top: -80px;
    right: -80px;
    width: 180px;
    height: 180px;
    border: 90px solid #000000;
  }

  @media (max-width: 480px) {
    top: -60px;
    right: -60px;
    width: 150px;
    height: 150px;
    border: 75px solid #000000;
  }
`;

const CardEllipsisBottom = styled.div`
  position: absolute;
  bottom: -120px;
  left: -120px;
  width: 250px;
  height: 250px;
  background: transparent;
  border-radius: 50%;
  border: 125px solid #000000;
  opacity: 10%;

  @media (max-width: 1024px) {
    bottom: -100px;
    left: -100px;
    width: 200px;
    height: 200px;
    border: 100px solid #000000;
  }

  @media (max-width: 768px) {
    bottom: -80px;
    left: -80px;
    width: 180px;
    height: 180px;
    border: 90px solid #000000;
  }

  @media (max-width: 480px) {
    bottom: -60px;
    left: -60px;
    width: 150px;
    height: 150px;
    border: 75px solid #000000;
  }
`;


const RewardPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

const RewardAmount = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 30px;
    margin-right: 5px;

    @media (max-width: 768px) {
      width: 26px;
    }

    @media (max-width: 480px) {
      width: 22px;
    }
  }

  h2 {
    font-size: 28px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 24px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;


const RedeemButtonWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const RedeemButton = styled(Button)`
  background: #fff;
  border-radius: 22px;
  width: 100px;
  font-size: 16px;
  padding: 10px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #f0f0f0;
  }

  @media (max-width: 768px) {
    width: 90px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    width: 80px;
    font-size: 12px;
    border-radius: 18px;
  }
`;

const ContentWrapper = styled(Content)`
  padding: 20px 40px;
  background: #fff;

  @media (max-width: 768px) {
    padding: 15px 34px;
  }

  @media (max-width: 480px) {
    padding: 10px 24px;
  }
`;

const EllipseTopRight = styled.div`
  position: fixed;
  top: -400px;
  right: -400px;
  width: 850px;
  height: 850px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 25%;

  @media (max-width: 1024px) {
    top: -350px;
    right: -350px;
    width: 700px;
    height: 700px;
    border: 80px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    top: -300px;
    right: -300px;
    width: 600px;
    height: 600px;
    border: 60px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 480px) {
    top: -250px;
    right: -250px;
    width: 500px;
    height: 500px;
    border: 50px solid rgba(255, 255, 255, 0.1);
  }
`;

