import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import { updateUserDetails } from "../redux/users/usersSlice";
import styled from "styled-components";
import _ from 'lodash';

const { Option } = Select;

const positions = [
  "Doctor",
  "Radiologist",
  "Nurse",
  "Medical Technician",
  "Administrator",
  "Manager",
  "Support Staff",
  "Marketing/Business Development",
  "Other",
];

const Profile: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { user, loading } = useSelector((state: RootState) => state.user);
  const [showOther, setShowOther] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (user) {
      const initialData = {
        name: `${user.first_name} ${user.last_name}`,
        mobile_number: user.mobile_number,
        email: user.email,
        workplace: user.workplace,
        positionType: positions.includes(user.position) ? user.position : "Other",
        position: positions.includes(user.position) ? "" : user.position,
      };

      setInitialValues(initialData);
      form.setFieldsValue(initialData);
      setShowOther(!positions.includes(user.position));
    }
  }, [user, form]);

  const handlePositionChange = (value: string) => {
    setShowOther(value === "Other");
    form.setFieldsValue({ position: value === "Other" ? "" : value });
  };

  const handleSubmit = async (values: any) => {
    try {
      const nameParts = values.name.trim().split(" ");
      const first_name = nameParts[0];
      const last_name = nameParts.slice(1).join(" ") || "";

      const updatedUser = {
        Id: user.Id,
        first_name,
        last_name,
        mobile_number: user.mobile_number,
        email: values.email,
        workplace: values.workplace,
        position: showOther ? values.position : values.positionType,
      };

      await dispatch(updateUserDetails(updatedUser)).unwrap();
      message.success("Profile updated successfully!");
    } catch (error: any) {
      message.error(error || "Failed to update profile. Please try again.");
    }
  };

  const hasChanges = () => {
    const currentValues = form.getFieldsValue();
    let updatedValues = { ...currentValues };

    // Remove position if positionType is not "Other"
    if (updatedValues.positionType !== "Other") {
      delete updatedValues.position;
    }

    const positionTypeChanged = updatedValues.positionType !== initialValues.positionType;
    const positionChanged =
      updatedValues.positionType === "Other" 
        ? updatedValues.position !== initialValues.position 
        : false; // Only compare position if positionType is "Other"

    // Compare other fields
    const hasChanges = positionTypeChanged || positionChanged || !_.isEqual(updatedValues, initialValues);

    console.log("Has Changes:", hasChanges);

    return hasChanges;
  };

  const onValuesChange = () => {
    setFormChanged(hasChanges());
  };

  return (
    <ProfileContainer>
      <Title>Edit Profile</Title>

      <StyledForm form={form} layout="vertical" onFinish={handleSubmit} onValuesChange={onValuesChange}>
        <Form.Item
          name="name"
          label={<FormItemLabel>Name</FormItemLabel>}
          rules={[{ required: true, message: "Please enter your full name" }]}
        >
          <StyledInput placeholder="Enter your full name" />
        </Form.Item>

        <Form.Item 
          name="mobile_number" 
          label={<FormItemLabel>Mobile Number</FormItemLabel>}
        >
          <StyledInput readOnly disabled />
        </Form.Item>

        <Form.Item
          name="email"
          label={<FormItemLabel>Email</FormItemLabel>}
          rules={[{ type: "email", message: "Enter a valid email" }]}
        >
          <StyledInput placeholder="Enter email (optional)" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="workplace"
              label={<FormItemLabel>Workplace</FormItemLabel>}
              rules={[{ required: true, message: "Enter your workplace" }]}
            >
              <StyledInput placeholder="Enter your workplace" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="positionType"
              label={<FormItemLabel>Position</FormItemLabel>}
              rules={[{ required: true, message: "Select your position" }]}
            >
              <StyledSelect 
                placeholder="Select your position" 
                onChange={(value) => handlePositionChange(value as string)}
              >
                {positions.map((position) => (
                  <Option key={position} value={position}>
                    {position}
                  </Option>
                ))}
              </StyledSelect>
            </Form.Item>
          </Col>
        </Row>

        {showOther && (
          <Form.Item
            name="position"
            label="Specify Position"
            rules={[{ required: true, message: "Please enter your position" }]}
          >
            <StyledInput placeholder="Enter your position" />
          </Form.Item>
        )}

        <Form.Item>
          <SubmitButton type="primary" htmlType="submit" loading={loading} disabled={!formChanged}>
            Save
          </SubmitButton>
        </Form.Item>
      </StyledForm>
    </ProfileContainer>
  );
};

export default Profile;

// 🔹 Styled Components
const ProfileContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 45px;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 35px;
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 25px;
    border-radius: 20px;
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #0a2fb6;
  margin-bottom: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const FormItemLabel = styled.label`
    font-size: 14px;
    font-weight: 600;
    color: #444;
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-tooltip {
    font-size: 10px;
    }    
`;

const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
  font-size: 16px;

  @media (max-width: 768px) {
    height: 38px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 36px;
    font-size: 14px;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;

  @media (max-width: 768px) {
    height: 38px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 36px;
    font-size: 14px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  height: 50px;
  background: #0a2fb6;
  border-radius: 60px;
  font-size: 16px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #082794;
  }

  @media (max-width: 768px) {
    height: 45px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    height: 40px;
    font-size: 14px;
    border-radius: 50px;
  }
`;

