import React from "react";
import { Layout, Button, Card, Carousel } from "antd";
import { GiftOutlined, WalletOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.svg";

// Client Logos with Names
const clients = [
  { name: "AMM Foundation", logo: require("../assets/client_one.png") },
  { name: "SK Hospitals", logo: require("../assets/client_two.png") },
  { name: "Sankar Super Speciality", logo: require("../assets/client_three.png") },
  { name: "RS Gaur Multispeciality Hospital", logo: require("../assets/client_four.png") },
];

const { Header, Content } = Layout;

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      {/* Header */}
      <StyledHeader>
        <EllipseTopRight />
        <LogoImg src={Logo} alt="Logo" />
        <ReferButton type="primary" onClick={() => navigate("/login")}>
          Login
        </ReferButton>
      </StyledHeader>

      <Content>
        {/* Hero Section */}
        <HeroSection>
          <EllipseTopRight />
          <HeroTitle>
            Unlock Rewards by Connecting Hospitals & Diagnostic Centers to AI-Powered Diagnostics!
          </HeroTitle>
          <HeroDesc>
            Refer hospitals and diagnostic centers to 5C Network and earn exclusive rewards! Partner with us in advancing AI-powered diagnostics across India.
          </HeroDesc>
          <ReferButton type="primary" onClick={() => navigate("/login")}>
            Refer Now
          </ReferButton>
        </HeroSection>

        {/* Why Partner Section */}
        <PartnerSection>
          <PartnerTitle>WHY USE PARTNER.5CNETWORK</PartnerTitle>
          <PartnerSubTitle>Easy to refer, simple to earn and absolutely rewarding!</PartnerSubTitle>
          <PartnerDesc>
            Our referral program helps you earn rewards effortlessly while introducing new clients to our platform.
          </PartnerDesc>
          <Benefits>
            <BenefitCard>
              <Icon>
                <GiftOutlined />
              </Icon>
              <h3>Easy Referrals</h3>
              <p>
                Effortlessly refer hospitals and diagnostic centers with a simple form, and let our system handle the rest. No follow-ups needed—just refer and earn!
              </p>
            </BenefitCard>
            <BenefitCard>
              <Icon>
                <WalletOutlined />
              </Icon>
              <h3>Multiple Options to Redeem Rewards</h3>
              <p>
                Choose how you want to enjoy your earnings! Redeem rewards as vouchers, 5C gifts, or discounts on billing—your rewards, your way!.
              </p>
            </BenefitCard>
          </Benefits>
        </PartnerSection>

        {/* Partner Benefits Section */}
        <BenefitSection>
            <BenefitTitle>Why partner with us?</BenefitTitle> 
            <BenefitSubTitle>Get exclusive bonuses, earn extra rewards, and track your progress effortlessly with a dedicated partner’s reward tracker!</BenefitSubTitle>
            <BenefitDesc>Ready to maximize your earnings? Join our Referral Program as a 5C Distributive Partner today!</BenefitDesc>
            <ReferButton type="primary" onClick={() => navigate("/login")}>Join as a Partner</ReferButton>
        </BenefitSection>

        {/* Key Clients Section with Carousel */}
        <ClientSection>
          <ClientTitle>Our Key Clients</ClientTitle>
          <CarouselContainer>
            <Carousel autoplay slidesToShow={3} dots={false}>
              {clients.map((client, index) => (
                <ClientCard key={index}>
                  <ClientLogo src={client.logo} alt={client.name} />
                  <ClientName>{client.name}</ClientName>
                </ClientCard>
              ))}
            </Carousel>
          </CarouselContainer>
        </ClientSection>

        {/* Step Section */}
        <StepsSection>
          <StepsTitle>Few easy steps and done</StepsTitle>
          <StepsSubTitle>Share, track and redeem – all in one place</StepsSubTitle>
          <StepsDesc>
            In just a few simple steps, you can start earning rewards by referring hospitals and diagnostic centers to our platform.
          </StepsDesc>
            <StepperContainer>
                <Stepper>
                    <StepItem>
                    <StepCircle finished={true}>1</StepCircle>
                    <StepText>Sign Up for the Referral Program</StepText>
                    <ProgressLine finished={true} />
                    </StepItem>
                    <StepItem>
                    <StepCircle finished={true}>2</StepCircle>
                    <StepText>Fill in the form and unlock your earnings</StepText>
                    <ProgressLine finished={false} />
                    </StepItem>
                    <StepItem>
                    <StepCircle finished={false}>3</StepCircle>
                    <StepText>Done, let’s continue the work.</StepText>
                    </StepItem>
                </Stepper>
            </StepperContainer>
        </StepsSection>

        {/* About Section */}
        <AboutSection>
          <AboutSubTitle>Know More about 5C Network!</AboutSubTitle>
          <AboutDesc>
            5C Network is at the forefront of multimodal AI technology applied to medical imaging. Our core product, the Bionic suite, is designed to transform radiology reporting by seamlessly integrating Computer Vision (CV) and LLM technologies.
          </AboutDesc>
          <ReferButton type="primary" onClick={() => window.open("https://5cnetwork.com", "_blank")}>
            Explore 5C
          </ReferButton>
        </AboutSection>
      </Content>
    </Layout>
  );
};

export default LandingPage;

/* Styled Components */
const StyledHeader = styled(Header)`
  background: #000d1a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  color: #fff;
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
`;

const LogoImg = styled.img`
  height: 40px;
  @media (max-width: 768px) {
    height: 30px;
  }
`;

const HeroSection = styled.div`
  background: #000d1a;
  color: #fff;
  text-align: center;
  padding: 50px 20px;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 60px;
  font-weight: bold;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const HeroDesc = styled.p`
  color: #90a3bf;
  margin: 15px 10%;
  @media (max-width: 768px) {
    margin: 10px 5%;
  }
`;

const EllipseTopRight = styled.div`
  position: fixed;
  top: -400px;
  right: -400px;
  width: 850px;
  height: 850px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.1);
  opacity: 40%;
  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    top: -250px;
    right: -250px;
  }
`;

const PartnerSection = styled.div`
  padding: 40px 20px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const PartnerTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #0a2fb6;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const PartnerSubTitle = styled.p`
  color: #040815;
  font-size: 36px;
  font-weight: 700;
  margin: 5px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const PartnerDesc = styled.p`
  color: #596780;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  margin: 10px 20%;
  @media (max-width: 768px) {
    margin: 10px 5%;
    font-size: 16px;
  }
`;

const ReferButton = styled(Button)`
  background: #0a2fb6;
  border: none;
  border-radius: 30px;
  z-index: 999;
  padding: 20px 30px;
  margin: 15px;
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
`;

const BenefitCard = styled(Card)`
  width: 350px;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Icon = styled.div`
  font-size: 32px;
  color: #0a2fb6;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const BenefitSection = styled.div`
  padding: 40px;
  text-align: left;
  background-color: #0d121f;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 70%;
  margin: 40px auto;
  @media (max-width: 768px) {
    padding: 30px;
    max-width: 90%;
  }
`;

const BenefitTitle = styled.h4`
  color: #6c7b96;
  font-size: 20px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const BenefitSubTitle = styled.h2`
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const BenefitDesc = styled.p`
  color: #b0b8c5;
  font-size: 16px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Benefits = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ClientSection = styled.div`
  padding: 20px 20px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ClientTitle = styled.h2`
  color: #0d121f;
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const CarouselContainer = styled.div`
  margin: 20px auto;
  width: 100%;
  @media (max-width: 768px) {
    margin: 15px auto;
  }
`;

const ClientCard = styled.div`
  text-align: center;
  font-size: 18px;
  flex-shrink: 0;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ClientLogo = styled.img`
  width: 60px;
  height: auto;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 50px;
  }
`;

const ClientName = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #000d1a;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StepsSection = styled.div`
  padding: 20px 20px;
  text-align: center;
  background-color: #0d121f;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const StepsTitle = styled.h2`
  color: #6c7b96;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const StepsSubTitle = styled.p`
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  margin: 5px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const StepsDesc = styled.p`
  color: #6c7b96;
  font-size: 20px;
  font-weight: 200;
  margin: 10px 22%;
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 10px 5%;
  }
`;

const StepperContainer = styled.div`
  background-color: #1c2028;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: fit-content;
  margin: 20px auto;
  @media (max-width: 768px) {
    width: 70%;
    padding: 20px;
  }
`;

const Stepper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StepItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const StepCircle = styled.div<{ finished: boolean }>`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props) => (props.finished ? "#0A2FB6" : "#fff")};
  color: ${(props) => (props.finished ? "#fff" : "#000")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid ${(props) => (props.finished ? "#0A2FB6" : "#6c7b96")};
  z-index: 2;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
`;

const StepText = styled.p`
  color: #fff;
  font-size: 15px;
  margin: 2px;
  line-height: 1.5;
  max-width: 250px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 200px;
  }
`;

const ProgressLine = styled.div<{ finished: boolean }>`
  position: absolute;
  left: 19px;
  top: 40px;
  height: 40px;
  width: 2px;
  background: ${(props) => (props.finished ? "#0A2FB6" : "transparent")};
  border-left: ${(props) => (!props.finished ? "2px dashed #6c7b96" : "none")};
  z-index: 1;

  @media (max-width: 768px) {
    left: 14px;
    top: 30px;
    height: 30px;
  }
`;

const AboutSection = styled.div`
  text-align: center;
  margin: 20px 20px;
  @media (max-width: 768px) {
    margin: 15px;
  }
`;

const AboutSubTitle = styled.p`
  color: #040815;
  font-size: 40px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const AboutDesc = styled.p`
  color: #596780;
  font-size: 20px;
  font-weight: 200;
  margin: 10px 22%;
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 10px 5%;
  }
`;
